<template>
<div id="applyInfo">
   <div class="applyInfo-wrap">
      <div class="introduce" v-for="(item,index) in introduceList" :key="index">
          <img :src="item" />
      </div>
      <router-link :to="{path:'/apply'}" v-show="settledType=='Y'">
        <div class="apply-btn">申请入驻</div>
      </router-link>
   </div>
</div>
</template>

<script>
export default {
  name: "applyInfo",
  data() {
    return {
      introduceList:[],
      settledType:'Y'
    }
  },
  mounted() {
    console.log(this.$route.query)
    this.introduceList = this.$route.query.introduceUrl.split(',')
    this.settledType =  this.$route.query.settledType
  },
  methods: {
   
  }
};
</script>

<style lang="scss" scoped>
#applyInfo {
  width: 100%;
  min-height: 200px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
  padding-top: 24px;
}

.applyInfo-wrap {
  width: 800px;
  min-height: 200px;
  margin: 0 auto;
}
.introduce{
  img{
    width: 100%;
  }
}
.apply-btn{
    width: 800px;
    height: 65px;
    margin: 20px 0;
    line-height: 65px;
    text-align: center;
    font-size: 20px;
    color: #FFFFFF;
    background: #0077FF;
    border-radius: 5px;
    cursor: pointer;
}
.apply-btn:hover{
   background: #5ca3f5;
}
</style>

